import getConfig from 'next/config';
import fetch from 'isomorphic-unfetch';

const { publicRuntimeConfig } = getConfig();

interface MajorCurrency {
  Code: string;
  Name: string;
  IsCustomerMajor: boolean;
  IsPopularCurrency: boolean;
}

export interface GetMajorCurrencyResponse {
  Currencies: MajorCurrency[];
}

interface Currency extends MajorCurrency {
  IsExotic: boolean;
  CountryName: string;
}

export interface GetCurrencyResponse {
  Currencies: Currency[];
}

const makeRequest = async <T>(url: string): Promise<T> => {
  try {
    const res = await fetch(url);

    if (res.status !== 200) {
      throw new Error('Http.get received non 200 response');
    }

    return await res.json();
  } catch (error) {
    throw error;
  }
};

export function getMajorCurrencyList(): Promise<GetMajorCurrencyResponse> {
  return makeRequest(
    `${publicRuntimeConfig.apiBaseUrl}/Quote.ApiService/Spot/Business/Currencies?format=json`
  );
}

export function getCurrencyList(): Promise<GetCurrencyResponse> {
  return makeRequest(
    `${publicRuntimeConfig.apiBaseUrl}/RefData.ApiService/Currencies?format=json`
  );
}
