import React from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TrackingProps } from '../../Types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const StyledTextField = withStyles((theme: Theme) => {
  const { colors, typeStyles } = theme.fox;

  const defaultLabelStyle = {
    color: colors.neutral.s700.hex,
    fontFamily: typeStyles.body2.fontFamily,
    fontSize: typeStyles.body2.fontSize,
    fontWeight: typeStyles.body2.fontWeight,
  };

  return {
    root: {
      margin: 0,
      '& .MuiFormLabel-root': {
        ...defaultLabelStyle,
        '&.Mui-focused': {
          color: colors.amethyst.s500.hex,
        },
        '&.Mui-disabled': {
          ...defaultLabelStyle,
        },
        '&.Mui-error': {
          color: colors.red.s500.hex,
        },
      },
      '&:hover .MuiFormLabel-root': {
        color: colors.neutral.s900.hex,
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: colors.neutral.s0.hex,
        paddingRight: `${theme.spacing(3)}px !important`,
        '& fieldset': {
          borderColor: colors.neutral.s500.hex,
        },
        '&:hover fieldset': {
          borderWidth: 2,
          borderColor: colors.neutral.s800.hex,
        },
        '&.Mui-focused fieldset': {
          borderWidth: 2,
          borderColor: colors.amethyst.s500.hex,
        },
        '&.Mui-disabled fieldset': {
          borderWidth: 1,
          borderColor: colors.neutral.s500.hex,
        },
        '& .MuiInputBase-input': {
          color: colors.neutral.s900.hex,
          fontFamily: typeStyles.body2.fontFamily,
          fontSize: typeStyles.body2.fontSize,
          fontWeight: typeStyles.body2.fontWeight,
          '&.Mui-disabled': {
            color: colors.neutral.s500.hex,
            cursor: 'not-allowed',
          },
        },
        '&.Mui-focused.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.red.s500.hex,
          },
        },
      },
      '& .MuiInputLabel-root': {
        '& .MuiInputLabel-asterisk': {
          display: 'none',
        },
      },
      '-webkit-appearance': 'textfield',
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
      '& input[type="number"]::-webkit-inner-spin-button, & input::-webkit-outer-spin-button':
        {
          '-webkit-appearance': 'none',
        },
    },
  };
})(MuiTextField);

const useStyles = makeStyles((theme: Theme) => {
  return {
    helperTextRoot: {
      '&.Mui-error': {
        color: theme.fox.colors.red.s500.hex,
      },
    },
  };
});
interface Props {
  isMultiline?: boolean;
}

export type FoxTextFieldProps = TextFieldProps & TrackingProps & Props;

const TextField = ({
  trackingId,
  isMultiline,
  ...props
}: FoxTextFieldProps): React.ReactElement<FoxTextFieldProps> => {
  const classes = useStyles(props);
  return (
    <StyledTextField
      {...props}
      multiline={isMultiline}
      margin="normal"
      variant="outlined"
      fullWidth
      FormHelperTextProps={{ className: classes.helperTextRoot }}
      inputProps={{
        ...props.inputProps,
        'data-tracking-id': trackingId,
      }}
    />
  );
};

export default TextField;
