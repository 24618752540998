export const FIRST_NAME_MAX_LENGTH = 50;
export const MIDDLE_NAME_MAX_LENGTH = 50;
export const LAST_NAME_MAX_LENGTH = 50;
export const EMAIL_MAX_LENGTH = 100;
export const JOB_TITLE_MAX_LENGTH = 50;
export const DRIVER_LICENSE_NUMBER_MAX_LENGTH = 50;
export const DIGITAL_KYC_FIELD_MAX_LENGTH = 1000;

export const NON_VALIDATE_NOR_OPTIONAL_ADDRESS_FIELDS = [
  'UnitType',
  'UnitNumber',
  'Premise',
];
