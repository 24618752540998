import { emailAddress } from './regexUtil';
import { verifyEmail, paytronVerifyEmail } from '@services/verify/verify';

let existingEmailList = {};
export const checkEmailExist = async (email): Promise<boolean> => {
  if (!emailAddress.test(email)) return;

  if (existingEmailList[email] !== undefined) return existingEmailList[email];

  const response = await verifyEmail(email);

  if (existingEmailList[email] === undefined) {
    existingEmailList = {
      ...existingEmailList,
      [email]: response.Valid,
    };
  }
  return response.Valid;
};

interface EmailCheck {
  valid: boolean;
  code?: string;
  message?: string;
}

export const validateEmail = async (
  email: string,
  bypassEmailVerification: boolean
): Promise<EmailCheck> => {
  if (!emailAddress.test(email)) {
    return { valid: false };
  }

  if (bypassEmailVerification) return { valid: true };

  const response = await verifyEmail(email);

  return {
    valid: !!response.Valid,
    message: response.Reason || response.Message,
    code: response.ReasonCode,
  } as EmailCheck;
};

interface PaytronEmailCheck {
  email: string;
  isValid: boolean;
  isExisting: boolean;
}

export const validatePaytronEmail = async (
  email: string,
  bypassEmailVerification: boolean
): Promise<PaytronEmailCheck> => {
  if (!emailAddress.test(email)) {
    return { email, isValid: false, isExisting: false };
  }

  if (bypassEmailVerification)
    return { email, isValid: true, isExisting: false };

  const response = await paytronVerifyEmail(email);

  return {
    email: response.email,
    isValid: !!response.isValid,
    isExisting: !!response.isExisting,
  } as PaytronEmailCheck;
};
