import React, { SyntheticEvent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@fox/components/TextField';

const StyledMUIAutocomplete = withStyles((theme) => ({
  paper: {
    margin: 0,
  },
  listbox: {
    padding: 0,
  },
  option: {
    padding: `${theme.spacing(1.3)}px`,
    '&[data-focus="true"]': {
      backgroundColor: 'white',
      border: `2px solid ${theme.fox.colors.neutral.s800.hex}`,
      borderRadius: 4,
      padding: `${theme.spacing(1.3) - 2}px`,
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.fox.colors.green.s100.hex,
    },
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  inputRoot: {
    ['&[class*="MuiOutlinedInput-root"]']: {
      '& .MuiAutocomplete-endAdornment': {
        right: theme.spacing(2),
        '& button svg': {
          fill: theme.fox.colors.neutral.s500.hex,
        },
      },
    },
  },
}))(MUIAutocomplete);

export interface Option {
  value: string | number;
  label: string;
}

interface AutocompleteProps {
  defaultValue?: Option;
  trackingId: string;
  label: string;
  name: string;
  error?: boolean;
  helperText?: string;
  onChange: ({ target: { name, value } }) => void;
  onBlur?: (e: SyntheticEvent) => void;
  options: Option[];
  isLoadingOptions?: boolean;
  isDisabled?: boolean;
  renderOption?: (option) => JSX.Element | string;
  getOptionDisabled?: (option) => boolean;
  textFieldInputProps?: {};
}

export default function Autocomplete({
  defaultValue,
  trackingId,
  label,
  name,
  error,
  helperText,
  onChange,
  onBlur,
  options,
  isLoadingOptions,
  isDisabled,
  renderOption,
  getOptionDisabled,
  textFieldInputProps,
}: AutocompleteProps): JSX.Element {
  const handleChange = (e, opt): void => {
    if (!onChange) return;
    const v = opt ? opt.value : '';
    e.persist();
    onChange({ ...e, target: { name: name, value: v } });
  };

  interface GetOptionSelected {
    getOptionSelected: (option: Option) => boolean;
  }

  const getOptionSelected = (): GetOptionSelected => {
    if (defaultValue) {
      return {
        getOptionSelected: (option) => option.value === defaultValue.value,
      };
    }
  };

  const setValueByDefault = (): { value: Option } =>
    defaultValue && { value: defaultValue };

  return (
    <StyledMUIAutocomplete
      id={`autocomplete-${name}`}
      {...setValueByDefault()}
      autoHighlight
      noOptionsText="Sorry, no options found."
      onChange={handleChange}
      onBlur={onBlur}
      options={options}
      getOptionLabel={(option: Option) => option.label}
      renderOption={renderOption}
      getOptionDisabled={getOptionDisabled}
      disabled={isDisabled}
      popupIcon={
        isLoadingOptions ? (
          <CircularProgress data-tracking-id="icon-circularLoading" size={24} />
        ) : (
          <ArrowDownIcon />
        )
      }
      defaultValue={defaultValue}
      {...getOptionSelected()}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          trackingId={trackingId}
          label={label}
          fullWidth
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // Hack to disable autofill issue on Chrome 😪
          }}
          InputProps={{
            ...params.InputProps,
            ...textFieldInputProps,
          }}
        />
      )}
    />
  );
}
