export const phoneRegex = /^[0-9]*$/;
export const countryCodeRegex = /^(\+?\d{1,4}|\d{1,7})$/;
export const nameRegex = /^([a-zA-Z\s\,\'\-\.\(\)]+)$/;
export const addressNumberRegex = /^[a-zA-Z0-9 /-]*$/;
export const addressRegex =
  /^(?![Pp][Oo] [Bb][Oo][Xx]|[Ll][Oo][Cc][Kk][Ee][Dd] [Bb][Aa][Gg]|[Pp][Rr][Ii][Vv][Aa][Tt][Ee] [Bb][Aa][Gg])([a-zA-Z0-9 /-])*$/;
export const citySuburbRegex = /^[a-zA-Z0-9 ]*$/;
export const lettersOnly = /^[A-Za-z]+$/;
export const emailAddress = /^[^\s@]+@[^\s@]+$/;
export const spentaNameRegex = /^([a-zA-Z\s\,\'\-\.\(\)]+)$/;
export const spentaJobTitleRegex = /^([a-zA-Z\s\,\'\-\.\(\):;]+)$/;
