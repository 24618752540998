import getConfig from 'next/config';
import fetch from 'isomorphic-unfetch';
import pDebounce from 'p-debounce';

const { publicRuntimeConfig } = getConfig();

export interface EmailResponse {
  Valid?: boolean;
  Reason?: string;
  ReasonCode?: string;
  Message?: string;
}

const makeRequest = async <T>(url: string, data, signal?): Promise<T> => {
  try {
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });

    return await res.json();
  } catch (error) {
    throw error;
  }
};

const debouncedVerifyEmailRequest = async (email): Promise<EmailResponse> => {
  return makeRequest(
    `${publicRuntimeConfig.apiBaseUrl}/User.ApiService/VerifyEmailAddress`,
    {
      EmailAddress: email,
    }
  );
};

export const verifyEmail = pDebounce(debouncedVerifyEmailRequest, 100);

export interface PaytronEmailResponse {
  email: string;
  isValid: boolean;
  isExisting: boolean;
}

const debouncedPaytronVerifyEmailRequest = async (
  email: string
): Promise<PaytronEmailResponse> => {
  return makeRequest(
    `${publicRuntimeConfig.paytronAppBaseUrl}/api/users/v1/validateEmail`,
    {
      email: email,
    }
  );
};

export const paytronVerifyEmail = pDebounce(
  debouncedPaytronVerifyEmailRequest,
  100
);

let abortController: AbortController;

export function verifyUsername(username): Promise<EmailResponse> {
  if (abortController !== undefined) {
    abortController.abort();
  }

  abortController = new AbortController();

  return makeRequest(
    `${publicRuntimeConfig.apiBaseUrl}/User.ApiService/ValidateUsername`,
    {
      Username: username,
    },
    abortController.signal
  );
}
